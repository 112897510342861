<template>
  <v-container fluid tag="section">
    <v-expansion-panels class="mt-4 mb-4" multiple v-model="openTabs">
      <v-expansion-panel>
        <v-expansion-panel-header :color="tab1">
          <div class="subtitle-1 font-weight-light">
            {{ $t("quiz.details") }}
          </div>
          <div class="subtitle-3 font-weight-light">
            {{ $t("quiz.details subtitle") }}
          </div>
          <share-dialog v-bind:type="type" v-if="General.can_copy"></share-dialog>
          <copy-dialog v-bind:type="type" v-if="canCopyQuiz" v-show="General.can_copy" ref="copyDialog"></copy-dialog>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="ml-3 mr-3">
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
            <div class="work-experiences">
              <div class="form-row">
                <div class="form-group">
                  <v-text-field :label="$t('quiz.name')" name="name" v-model="General.name" :rules="rules.name"
                    required />
                  <v-select :items="status" :label="$t('quiz.status')" v-if="qid" v-model="General.status"
                    item-text="text" item-value="id" :rules="rules.name" required></v-select>
                  <v-textarea :label="$t('quiz.instructions')" name="instructions" v-model="General.instructions"
                    :rules="rules.name" required></v-textarea>
                  <v-textarea :label="$t('quiz.complete message')" name="complete_message"
                    v-model="General.complete_message" required></v-textarea>
                  <v-textarea :label="$t('quiz.remarks')" name="remarks" v-model="General.remarks"
                    required></v-textarea>
                  <v-row v-if="General">
                    <v-col cols="12" md="8" class="mb-5" order="3" order-md="1">
                      <b>{{ $t("quiz.formula") }}</b>
                      <highlightable-input id="formula" ref="formula" class="formula"
                        highlight-style="background-color:#ffcc44; color:black" :highlight-enabled="true"
                        :highlight="highlight" v-model="General.formula" />
                    </v-col>
                    <v-col cols="6" md="2" order="1" order-md="2">
                      <b>{{ $t("quiz.subscales") }}</b>
                      <ul class="functions">
                        <li v-for="(s, index) in General.subscales" :key="'sub' + index" @click="addVariable(s)">
                          {{ s }}
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="6" md="2" order="2" order-md="3">
                      <b>{{ $t("quiz.functions") }}</b>
                      <ul class="functions">
                        <li @click="addFunction('min')">min(a,b,c ...)</li>
                        <li @click="addFunction('max')">max(a,b,c ...)</li>
                        <li @click="addFunction('sqrt')">sqrt(x)</li>
                        <li @click="addFunction('round')">round(x,n)</li>
                      </ul>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-col cols="12" class="mb-5">
                <v-btn v-if="!success" color="primary" class="mr-2 float-right" type="submit">
                  {{ $t("Save") }}
                </v-btn>
              </v-col>
              <v-alert type="success" class="mt-3" v-if="success">
                {{ $t("quiz.saved") }}
              </v-alert>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="qid">
        <v-expansion-panel-header :color="tab2">
          <div class="subtitle-1 font-weight-light">
            {{ $t("norm.categories") }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <categories-table v-bind:qid="qid" v-on:updated="refresh_count2++"></categories-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="qid">
        <v-expansion-panel-header :color="tab3">
          <div class="subtitle-1 font-weight-light">
            {{ $t("quiz.subscales") }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <subscales-table v-bind:qid="qid" v-bind:refresh="refresh_count2"
            v-on:updated="refresh_count++"></subscales-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="qid">
        <v-expansion-panel-header :color="tab4">
          <div class="subtitle-1 font-weight-light">
            {{ $t("quiz.questions") }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <questions-table v-bind:qid="qid" v-bind:refresh="refresh_count"
            v-bind:published="General.status"></questions-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import SubscalesTable from "@/pages/quizzes/SubscalesTable";
import QuestionsTable from "@/pages/quizzes/QuestionsTable";
import categoriesTable from "@/pages/quizzes/CategoryTable";
import ShareDialog from "@/pages/quizzes/ShareDialog";
import CopyDialog from "@/pages/quizzes/CopyDialog";
import HighlightableInput from "vue-highlightable-input";
export default {
  components: {
    SubscalesTable,
    categoriesTable,
    QuestionsTable,
    ShareDialog,
    CopyDialog,
    HighlightableInput,
  },

  data() {
    return {
      canCopyQuiz: false, // TODO:remove after clone function is fixed
      apiQuery: "/quiz/",
      refresh_count: 0,
      refresh_count2: 0,
      type: "App\\Models\\Quiz",
      valid: false,
      success: false,
      General: {},
      openTabs: [],
      Quiz: [],
      Questions: [],
      Answers: [],
      qid: "",
      status: [
        { id: "draft", text: this.$t("quiz.statuses.draft") },
        { id: "published", text: this.$t("quiz.statuses.published") },
        { id: "archived", text: this.$t("quiz.statuses.archived") },
      ],
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("quiz.field"),
            }),
        ],
      },
      editableDiv: null,
      caret: 0,
      highlight: ["acxcxczc"],
    };
  },

  mounted() {
    if (this.$route.params.id && this.$route.params.id != "create") {
      this.qid = this.$route.params.id;
      this.get(this.apiQuery + this.qid)
        .then((data) => {
          this.General = data;
          this.highlight = data.subscales.map((x) => "[" + x + "]");
          if (this.canCopyQuiz && this.General.can_copy) {
            this.$refs.copyDialog.setName(
              this.General.name + this.$t("copy_x")
            );
          }
        })
        .catch((error) => {
          this.alert(error.message);
        });
    } else {
      this.General.status = "draft";
      this.openTabs.push(0);
    }
  },
  updated: function () {
    this.$nextTick(function () {
      if (this.editableDiv || !this.$refs["formula"]) {
        return;
      }
      this.editableDiv = this.$refs["formula"].$el;
      //this.editableDiv.addEventListener("click", this.cursor_position);
      //this.editableDiv.addEventListener("keyup", this.cursor_position);
      this.editableDiv.addEventListener("click", this.getCurrentCursorPosition);
      this.editableDiv.addEventListener("keyup", this.getCurrentCursorPosition);
    });
  },
  computed: {
    tab1() {
      return this.openTabs.includes(0) ? "primary" : "closed";
    },
    tab2() {
      return this.openTabs.includes(1) ? "primary" : "closed";
    },
    tab3() {
      return this.openTabs.includes(2) ? "primary" : "closed";
    },
    tab4() {
      return this.openTabs.includes(3) ? "primary" : "closed";
    },
  },
  methods: {
    node_walk(node, func) {
      var result = func(node);
      for (
        node = node.firstChild;
        result !== false && node;
        node = node.nextSibling
      )
        result = this.node_walk(node, func);
      return result;
    },
    cursor_position() {
      var sel = window.getSelection();
      var cum_length = [0, 0];

      var elem = this.editableDiv;
      if (sel.anchorNode == elem)
        cum_length = [sel.anchorOffset, sel.extentOffset];
      else {
        var nodes_to_find = [sel.anchorNode, sel.extentNode];
        if (
          !elem.contains(sel.anchorNode) ||
          (sel.extentNode && !elem.contains(sel.extentNode))
        )
          return undefined;
        else {
          var found = [0, 0];
          var i;
          this.node_walk(elem, function (node) {
            for (i = 0; i < 2; i++) {
              if (node == nodes_to_find[i]) {
                found[i] = true;
                if (found[i == 0 ? 1 : 0]) return false; // all done
              }
            }

            if (node.textContent && !node.firstChild) {
              for (i = 0; i < 2; i++) {
                if (!found[i]) cum_length[i] += node.textContent.length;
              }
            }
          });
          cum_length[0] += sel.anchorOffset;
          cum_length[1] += sel.extentOffset;
        }
      }
      this.caret = cum_length[0];
    },
    createRange(node, chars, range) {
      if (!range) {
        range = document.createRange();
        range.selectNode(node);
        range.setStart(node, 0);
      }

      if (chars.count === 0) {
        range.setEnd(node, chars.count);
      } else if (node && chars.count > 0) {
        if (node.nodeType === Node.TEXT_NODE) {
          if (node.textContent.length < chars.count) {
            chars.count -= node.textContent.length;
          } else {
            range.setEnd(node, chars.count);
            chars.count = 0;
          }
        } else {
          for (var lp = 0; lp < node.childNodes.length; lp++) {
            range = this.createRange(node.childNodes[lp], chars, range);

            if (chars.count === 0) {
              break;
            }
          }
        }
      }
      return range;
    },
    setCurrentCursorPosition(chars) {
      if (chars >= 0) {
        var selection = window.getSelection();

        var range = this.createRange(document.getElementById("formula"), {
          count: chars,
        });

        if (range) {
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    },
    isChildOf(node, parentId) {
      while (node !== null) {
        if (node.id === parentId) {
          return true;
        }
        node = node.parentNode;
      }

      return false;
    },
    getCurrentCursorPosition() {
      var parentId = "formula";
      var selection = window.getSelection(),
        charCount = -1,
        node;

      if (selection.focusNode) {
        if (this.isChildOf(selection.focusNode, parentId)) {
          node = selection.focusNode;
          charCount = selection.focusOffset;

          while (node) {
            if (node.id === parentId) {
              break;
            }

            if (node.previousSibling) {
              node = node.previousSibling;
              charCount += node.textContent.length;
            } else {
              node = node.parentNode;
              if (node === null) {
                break;
              }
            }
          }
        }
      }
      this.caret = charCount;
    },
    submit() {
      if (!this.qid) {
        this.post("/quiz", this.General)
          .then((res) => {
            this.qid = res;
            if (this.canCopyQuiz && this.General.can_copy) {
              this.$refs.copyDialog.setName(
                this.General.name + this.$t("copy_x")
              );
            }
            this.openTabs = [];
          })
          .catch((error) => {
            this.alert({
              text: error.message,
              type: "error",
              title: "Oops",
            });
          });
      } else {
        this.patch("/quiz/" + this.qid, this.General)
          .then((res) => {
            this.qid = res;
            if (this.canCopyQuiz && this.General.can_copy) {
              this.$refs.copyDialog.setName(
                this.General.name + this.$t("copy_x")
              );
            }
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 2000);
          })
          .catch((error) => {
            this.alert({
              text: error.message,
              type: "error",
              title: "Oops",
            });
          });
      }
    },
    addFunction(func) {
      var c = this.editableDiv.textContent;
      var pos = this.caret + func.length + 1;
      this.editableDiv.textContent =
        c.substr(0, this.caret) + func + "()" + c.substr(this.caret);
      this.$refs["formula"].handleChange();
      this.setCurrentCursorPosition(pos);
      this.caret = pos;
    },
    addVariable(s) {
      var c = this.editableDiv.textContent;
      var pos = this.caret + s.length + 2;
      this.editableDiv.textContent =
        c.substr(0, this.caret) + "[" + s + "]" + c.substr(this.caret);
      this.$refs["formula"].handleChange();
      this.setCurrentCursorPosition(pos);
      this.caret = pos;
    },
  },
};
</script>

<style scoped>
.formula {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
  min-height: 100px;
  border: 1px solid lightgray;
}

ul.functions {
  list-style: none;
  cursor: pointer;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

ul.functions>li:hover {
  background-color: antiquewhite;
}
</style>
