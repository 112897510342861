<template>
  <v-container id="user-table" fluid tag="section">
    <subscale-form v-bind:qid="qid" ref="editForm" @itemSaved="onSaved"></subscale-form>
    <delete-dialog ref="deleteConfirm" :url="apiQuery" :title="$t('quiz.Delete subscale')"
      :confirmation="$t('quiz.subscale deleted')" @itemDeleted="onDeleted">
      <template v-slot:default="item">{{ $t("quiz.sure subscale", item) }}</template>

      <template v-slot:error="error">
        <v-alert v-if="error.message" tile class="mb-0" type="error">{{
      error.message
    }}</v-alert>
      </template>

      <template v-slot:confirmed="item">
        <v-alert v-if="item.id" tile type="success" class="mb-0">{{ $t("quiz.subscale") }} {{ item.scale_name }}
          {{ $t("quiz.deleted") }}</v-alert>
      </template>
    </delete-dialog>

    <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="total" :footer-props="{
      'items-per-page-options': [20, 50, 100],
    }">
      <template v-slot:[`item.id`]="{ item }">
        <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
        <span v-else>{{ item.id }}</span>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />
          <v-btn color="primary" dark class="mb-2" @click="onAdd">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("quiz.add") }}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
        <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";

import SubscaleForm from "@/pages/quizzes/SubscaleForm";
import DeleteDialog from "@/components/crud/DeleteDialog";
export default {
  components: {
    DeleteDialog,
    SubscaleForm,
  },
  props: ["qid", "refresh"],
  mixins: [crudMixin],
  watch: {
    // whenever question changes, this function will run
    refresh: function () {
      //refresh list here
      this.loading = true;
      this.get(this.apiQuery, this.options)
        .then((data) => {
          if (data.hasOwnProperty.call(data, "data")) {
            this.items = data.data;
            this.total = data.total;
          } else {
            this.items = data;
            this.total = data.length;
          }
          this.loading = false;
        })
        .catch((error) => {
          alert(error.message);
          this.loading = false;
          this.$emit("serverError", error);
        });
    },
  },

  data() {
    return {
      apiQuery: "/subscale/",
      headers: [
        { text: this.$t("quiz.subscale name"), value: "scale_name" },
        { text: this.$t("quiz.text min"), value: "text_min" },
        { text: this.$t("quiz.text max"), value: "text_max" },
        { text: this.$t("quiz.total questions"), value: "total_questions" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
    };
  },

  mounted() {
    this.options.quiz_id = this.qid;
  },
  methods: {
  },
};
</script>
