<template>
  <v-dialog v-model="showFormDialog" max-width="90vw" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveQuestions">
        <v-card-text>
          <v-row no-gutters class="ml-2">
            <v-col cols="12" sm="6" md="8">
              <v-textarea v-model="form.question_text" :label="$t('quiz.question text')"></v-textarea>
            </v-col>
            <v-col cols="6" md="4" class="pl-3">
              <v-select v-model="form.subscales" :items="forSelect(subscales)" item-text="text" item-value="id"
                :label="$t('quiz.subscale')" class="mt-1" multiple chips :readonly="checkpublished === true"></v-select>

              <v-checkbox v-if="!editedItem" v-model="newSubscale" class="mt-1" :label="$t('quiz.new subscale')"
                :readonly="checkpublished === true"></v-checkbox>

              <v-alert v-if="!editedItem && newSubscale" class="info">{{ $t("quiz.subscale copy") }}
              </v-alert>

              <v-text-field v-if="!editedItem && newSubscale" v-model="subscaleName" :label="$t('quiz.subscale name')"
                :readonly="checkpublished === true"></v-text-field>

              <v-select v-model="form.polarity" :items="polarities" item-text="text" item-value="id"
                :label="$t('quiz.polarity')" class="mt-1"></v-select>
            </v-col>
          </v-row>

          <v-col cols="12">
            <v-textarea v-model="form.comment" :label="$t('quiz.comment')"></v-textarea>
          </v-col>

          <h4>
            {{ $t("quiz.answers") }}
          </h4>

          <v-row no-gutters v-for="(item, index) in answers" :key="item.id" class="mt-2 ml-2">
            <v-col cols="8" sm="10">
              <v-text-field v-model="item.answer_text" :label="$t('quiz.answer text')" class="mt-1"
                :rules="rules.name"></v-text-field>
            </v-col>
            <v-col cols="4" sm="2">
              <div class="score">
                <v-text-field v-model="item.answer_score" :label="$t('quiz.answer score')" :rules="rules.name"
                  type="number" class="ml-1 mt-1" :readonly="checkpublished === true"></v-text-field>
                <div class="text-right pt-5 ml-2">
                  <v-icon v-if="!checkpublished" small @click="answerDeleteDB(index, item)">mdi-delete</v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters v-for="(item, index) in nanswers" :key="item.id" class="mt-0 ml-2">
            <v-col cols="8" sm="10">
              <v-text-field v-model="item.answer_text" :label="$t('quiz.answer text')"
                :rules="rules.name"></v-text-field>
            </v-col>
            <v-col cols="4" sm="2">
              <div class="score">
                <v-text-field v-model="item.answer_score" :label="$t('quiz.answer score')" :rules="rules.name"
                  type="number" class="ml-1" :readonly="checkpublished === true"></v-text-field>
                <div class="text-right pt-5 ml-2">
                  <v-icon v-if="!checkpublished" small @click="answerDelete(index, item)">mdi-delete</v-icon>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="!checkpublished">
            <v-col>
              <v-checkbox @change="saveKeepAnswers" v-model="keepAnswers" color="primary" class="pr-3 pl-3"
                :label="$t('quiz.keep answers')"></v-checkbox>
              <v-btn @click="addQuestion(1)" color="primary" class="pr-3 pl-3 float-right"><v-icon
                  left>mdi-beaker-plus</v-icon>{{ $t("quiz.add answer") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
    $t("Cancel")
  }}</v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">{{
    $t("Save")
  }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear v-if="sending" color="deep-purple accent-4" indeterminate rounded
        height="6"></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
    $t(errorMessage)
  }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
    $t("quiz.question saved", savedItem)
        }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
//import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: ["qid", "refresh", "published"],
  mounted() {
    this.defaultItem.quiz_id = this.qid;
    this.loadSubscales();
  },

  watch: {
    refresh: function () {
      this.loadSubscales();
    },
    showFormDialog: function () {
      this.resetForm();
    },
  },
  data() {
    return {
      apiQuery: "/question/",
      deleteDialogQuestion: true,
      subscales: [],
      answers: [],
      nanswers: [],
      kanswers: [],
      keepAnswers: false,
      defaultItem: {
        polarity: 1,
      },
      polarities: [
        { id: 1, text: "+" },
        { id: -1, text: "-" },
      ],
      newSubscale: false,
      subscaleName: "",
      id: 0,
      rules: {
        name: [
          (v) =>
            !!v ||
            v === 0 ||
            this.$t("validation.required", {
              name: this.$t("quiz.field"),
            }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("quiz.field"),
            }),
        ],
      },
    };
  },

  methods: {
    loadSubscales() {
      this.get("/question/create/", { quiz_id: this.qid }).then((data) => {
        this.subscales = data.subscales;
        if (
          Object.keys(this.subscales).length &&
          !this.defaultItem.subscales
        ) {
          this.form.subscales = this.defaultItem.subscales = [parseInt(
            Object.keys(this.subscales)[Object.keys(this.subscales).length - 1]
          )];
        }
      });
    },
    saveQuestions() {
      this.defaultItem.subscales = this.form.subscales;
      this.savedItem = null;
      this.form = {
        ...this.form,
        //id: this.form.id,
        quiz_id: this.qid,
        //Question: this.form,
        answers: this.answers,
        nanswers: this.nanswers,
        newSubscale: this.newSubscale,
        subscaleName: this.subscaleName,
      };
      this.saveKeepAnswers();
      this.saveItem();
    },
    saveKeepAnswers() {
      this.kanswers = [];
      if (this.keepAnswers) {
        for (var i in this.answers) {
          let ans = JSON.parse(JSON.stringify(this.answers[i]));
          delete ans["id"];
          this.kanswers.push(ans);
        }
        for (i in this.nanswers) {
          this.kanswers.push(JSON.parse(JSON.stringify(this.nanswers[i])));
        }
      }
    },
    resetForm() {
      if (this.showFormDialog) {
        if (!this.errorMessage) {
          this.subscaleName = "";
          if (this.editedItem === null) {
            this.answers = [];
            this.nanswers = this.kanswers;
            if (this.nanswers.length == 0) {
              this.addQuestion(2);
            }
          } else {
            if (this.id != this.form.id) {
              this.answers = [];
              this.nanswers = [];
            }
            this.get("/answer/", { id: this.form.id }).then((data) => {
              this.id = this.form.id;
              this.nanswers = [];
              this.answers = data;
            });
          }
          if (this.newSubscale) {
            this.loadSubscales();
          }
        }
        if (!this.form.subscales) {
          this.form.subscales = this.defaultItem.subscales;
        }
      }
    },
    edit(index) {
      this.patch("/answer/" + index.id, index);
    },
    answerDelete(index) {
      this.nanswers.splice(index, 1);
    },
    answerDeleteDB(index, item) {
      this.delete("/answer/", item.id)
        .then(() => {
          this.answers.splice(index, 1);
        })
        .catch((error) => {
          this.errorMessage = error.error || error.message;
          this.$emit("serverError", error);
        });
    },
    addQuestion(cnt = 1) {
      for (var i = 0; i < cnt; i++) {
        this.nanswers.push({
          answer_text: "",
          answer_score: "",
        });
      }
    },
  },
  computed: {
    checkpublished() {
      return this.published === "published" ? true : false;
    },
    formTitle() {
      return this.editedItem === null
        ? this.$t("quiz.Add Question")
        : this.$t("quiz.Edit Question");
    },
  },
};
</script>

<style scoped>
.score {
  display: flex;
}
</style>
