<template>
  <v-dialog v-model="dialog" width="500" @click:outside="closeDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" class="float-right mr-5"
        >mdi-content-copy</v-icon
      >
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("copy as") }} :</span>
      </v-card-title>
      <v-form ref="form">
        <v-card-text>
          <v-text-field v-model="form.name" :label="$t('Name')"> </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" @click="closeDialog">{{ $t("Close") }}</v-btn>
          <v-btn color="primary" @click="saveItem">{{ $t("Copy") }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("quiz.copied")
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>
<script>
import crudMixin from "mixins/crudForm";
export default {
  mixins: [crudMixin],
  props: ["type"],
  mounted() {
    this.form.model_id = this.$route.params.id;
    this.form.model_type = this.type;
    this.apiQuery = "/quiz/" + this.$route.params.id + "/copy";
  },
  data() {
    return {
      form: {},
      errorMessage: "",
      dialog: false,
    };
  },
  methods: {
    setName(n) {
      this.form.name = n;
    },
    closeDialog() {
      this.dialog = false;
      this.savedItem = null;
      this.errorMessage = null;
    },
  },
};
</script>
