<template>
  <v-dialog v-model="showFormDialog" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.category_name"
                  prepend-icon="directions"
                  :label="$t('norm.norm name')"
                  :rules="rules.name"
                ></v-text-field>
                <v-select
                  v-model="form.gender"
                  prepend-icon="mdi-gender-male-female"
                  :items="gender"
                  item-text="text"
                  item-value="id"
                  :label="$t('norm.gender')"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.min_age"
                  prepend-icon="mdi-arrow-up-bold"
                  :label="$t('norm.min age')"
                  type="number"
                  :rules="[
                    () =>
                      getInputError('min_age', {
                        name: $t('norm.min age'),
                        with: $t('norm.max age'),
                        lt: $t('norm.max age'),
                      }),
                  ]"
                  @input="resetInputError('min_age')"
                ></v-text-field>
                <v-text-field
                  v-model="form.max_age"
                  prepend-icon="mdi-arrow-down-bold"
                  :label="$t('norm.max age')"
                  type="number"
                  :rules="[
                    () =>
                      getInputError('max_age', {
                        name: $t('norm.max age'),
                        with: $t('norm.min age'),
                        gt: $t('norm.min age'),
                      }),
                  ]"
                  @input="resetInputError('max_age')"
                ></v-text-field>
                <!-- to add nr questions in edit scenario -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("norm.category saved", savedItem)
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: ["qid"],
  mounted() {
    this.defaultItem.quiz_id = this.qid;
  },
  data() {
    return {
      apiQuery: "/category/",
      gender: [
        { id: "male", text: this.$t("norm.gender_male") },
        { id: "female", text: this.$t("norm.gender_female") },
        { id: "any", text: this.$t("norm.gender_any") },
      ],
      defaultItem: {
        gender: "any",
      },
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("norm.norm") }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("quiz.field"),
            }),
        ],
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("norm.add category")
        : this.$t("norm.edit category");
    },
  },

  methods: {
  },
};
</script>
