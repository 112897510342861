var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{on:{"click:outside":_vm.closeForm},model:{value:(_vm.showFormDialog),callback:function ($$v) {_vm.showFormDialog=$$v},expression:"showFormDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveItem($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"directions","label":_vm.$t('norm.norm name'),"rules":_vm.rules.name},model:{value:(_vm.form.category_name),callback:function ($$v) {_vm.$set(_vm.form, "category_name", $$v)},expression:"form.category_name"}}),_c('v-select',{attrs:{"prepend-icon":"mdi-gender-male-female","items":_vm.gender,"item-text":"text","item-value":"id","label":_vm.$t('norm.gender')},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-arrow-up-bold","label":_vm.$t('norm.min age'),"type":"number","rules":[
                  function () { return _vm.getInputError('min_age', {
                      name: _vm.$t('norm.min age'),
                      with: _vm.$t('norm.max age'),
                      lt: _vm.$t('norm.max age'),
                    }); } ]},on:{"input":function($event){return _vm.resetInputError('min_age')}},model:{value:(_vm.form.min_age),callback:function ($$v) {_vm.$set(_vm.form, "min_age", $$v)},expression:"form.min_age"}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-arrow-down-bold","label":_vm.$t('norm.max age'),"type":"number","rules":[
                  function () { return _vm.getInputError('max_age', {
                      name: _vm.$t('norm.max age'),
                      with: _vm.$t('norm.min age'),
                      gt: _vm.$t('norm.min age'),
                    }); } ]},on:{"input":function($event){return _vm.resetInputError('max_age')}},model:{value:(_vm.form.max_age),callback:function ($$v) {_vm.$set(_vm.form, "max_age", $$v)},expression:"form.max_age"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","disabled":_vm.sending},on:{"click":_vm.closeForm}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('v-btn',{attrs:{"type":"submit","disabled":_vm.sending,"color":"primary"}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1),(_vm.sending)?_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}}):_vm._e(),(_vm.errorMessage)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.$t(_vm.errorMessage)))]):_vm._e(),(_vm.savedItem)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v(_vm._s(_vm.$t("norm.category saved", _vm.savedItem)))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }