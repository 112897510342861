var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-table","fluid":"","tag":"section"}},[_c('questions-form',{ref:"editForm",attrs:{"qid":_vm.qid,"refresh":_vm.refresh,"published":_vm.published},on:{"itemSaved":_vm.onSaved}}),_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('quiz.delete question title'),"confirmation":_vm.$t('quiz.question deleted')},on:{"itemDeleted":_vm.onDeletedUpdated},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_vm._v(_vm._s(_vm.$t("quiz.are you sure", item)))]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(error.message))]):_vm._e()]}},{key:"confirmed",fn:function(item){return [(item.id)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v(_vm._s(_vm.$t("quiz.item"))+" "+_vm._s(item.question_text)+" "+_vm._s(_vm.$t("quiz.deleted")))]):_vm._e()]}}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"search":_vm.search,"loading":_vm.loading,"custom-filter":_vm.filterSubscales,"footer-props":{
      'items-per-page-options': [20, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.$t('quiz.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),(!_vm.checkpublished)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("quiz.add"))+" ")],1):_vm._e()],1)]},proxy:true},{key:"item.number",fn:function(ref){
    var item = ref.item;
return [_c('div',{on:{"mouseover":function($event){_vm.hover = item.id}}},[(!_vm.loading && _vm.hover == item.id)?_c('v-select',{staticClass:"small-select",staticStyle:{"margin-bottom":"-2px"},attrs:{"dense":"","items":_vm.numbers},on:{"change":function($event){return _vm.saveNumber(item)}},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}}):(_vm.changed == item.id)?_c('v-chip',{staticStyle:{"margin-left":"-13px"},attrs:{"color":"red"}},[_vm._v(" "+_vm._s(item.number)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.number)+" ")])],1)]}},{key:"item.subscales",fn:function(ref){
    var item = ref.item;
return [(_vm.subscales)?_vm._l((item.subscales),function(s,i){return _c('v-chip',{key:("subscale-" + i)},[_vm._v(_vm._s(_vm.subscales[s]))])}):_vm._e()]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [(item.isNew)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]),(!_vm.checkpublished)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }