<template>
  <v-container id="user-table" fluid tag="section">
    <questions-form
      v-bind:qid="qid"
      v-bind:refresh="refresh"
      v-bind:published="published"
      ref="editForm"
      @itemSaved="onSaved"
    ></questions-form>

    <delete-dialog
      ref="deleteConfirm"
      :url="apiQuery"
      :title="$t('quiz.delete question title')"
      :confirmation="$t('quiz.question deleted')"
      @itemDeleted="onDeletedUpdated"
    >
      <template v-slot:default="item"
        >{{ $t("quiz.are you sure", item) }}</template
      >

      <template v-slot:error="error">
        <v-alert v-if="error.message" tile class="mb-0" type="error">{{
          error.message
        }}</v-alert>
      </template>

      <template v-slot:confirmed="item">
        <v-alert v-if="item.id" tile type="success" class="mb-0"
          >{{ $t("quiz.item") }} {{ item.question_text }}
          {{ $t("quiz.deleted") }}</v-alert
        >
      </template>
    </delete-dialog>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :search="search"
      :loading="loading"
      :custom-filter="filterSubscales"
      :footer-props="{
        'items-per-page-options': [20, 50, 100],
      }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('quiz.search')"
          class="mx-4"
        ></v-text-field>
        <v-toolbar flat>
          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="onAdd"
            v-if="!checkpublished"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t("quiz.add") }}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.number`]="{ item }">
          <div
            @mouseover="hover = item.id"
          >
          <v-select
            dense
            v-if="!loading && hover == item.id"
            class="small-select"
            v-model="item.number"
            :items="numbers"
            @change="saveNumber(item)"
            style="margin-bottom:-2px"
          ></v-select>
          <v-chip v-else-if="changed == item.id" color="red" style="margin-left:-13px">
            {{ item.number }}
          </v-chip>
          <span v-else>
            {{ item.number }}
          </span>
          </div>
      </template>

      <template v-slot:[`item.subscales`]="{ item }">
        <template v-if="subscales">
          <v-chip v-for="(s, i) in item.subscales" :key="`subscale-${i}`">{{ subscales[s] }}</v-chip>
        </template>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
        <span v-else>{{ item.id }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
        <v-icon v-if="!checkpublished" small @click="onDelete(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";

import QuestionsForm from "@/pages/quizzes/QuestionsForm";
import DeleteDialog from "@/components/crud/DeleteDialog";
export default {
  components: {
    DeleteDialog,
    QuestionsForm,
  },
  computed: {
    checkpublished() {
      return this.published === "published" ? true : false;
    },
    numbers() {
      return Array(this.total).fill().map((_, i) => i+1);
    },
    subscales() {
      return this.$refs.editForm.subscales;
    }
  },
  props: ["qid", "refresh", "published"],
  mixins: [crudMixin],

  data() {
    return {
      apiQuery: "/question/",
      search: "",
      headers: [
        { text: this.$t("quiz.number"), value: "number" },
        { text: this.$t("quiz.subscale name"), value: "subscales" },
        { text: this.$t("quiz.question text"), value: "question_text" },
        { text: this.$t("quiz.total answers"), value: "total_answers" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      hover: 0,
      changed: 0,
    };
  },

  mounted() {
    this.options.quiz_id = this.qid;
    this.options.sortBy = ["number"];
    this.options.sortDesc = [false];
  },
  methods: {
    onDeletedUpdated(item, index) {
      this.$set(this.options, "deleted", item.id);
      this.$emit("updated-field");
      this.onDeleted(item, index);
    },
    filterSubscales(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    onSaved(item, index) {
      this.$emit("updated-field");
      if (index === -1) {
        this.total++;
        this.$refs.editForm.loadSubscales();
        item.isNew = true;
        this.items.push(item);
        window.setTimeout(() => {
          this.$refs.editForm.clearForm();
          this.$refs.editForm.resetForm();
        }, 2000);
      } else {
        Object.assign(this.items[index], item);
        this.$refs.editForm.resetForm();
      }
    },
    saveNumber(item) {
      if (!this.loading) {
        this.loading = true;
        this.hover = 0;
        this.changed = item.id;
        this.$set(this.options, "number", item.id + '-' + item.number);
        setTimeout(() => {this.changed = 0}, 1000);
      }
    },
  },
};
</script>

<style scoped>
.small-select {
  width: 70px;
}
</style>