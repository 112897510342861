<template>
  <v-dialog v-model="showFormDialog" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveNorms">
        <v-card-text>
          <v-container>
            <v-row class="m-0">
              <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field
                  v-model="form.scale_name"
                  :label="$t('quiz.subscale name')"
                  :rules="rules.name"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3" class="pt-0 pb-0">
                <v-text-field
                  v-model="form.text_min"
                  :label="$t('quiz.text min')"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3" class="pt-0 pb-0">
                <v-text-field
                  v-model="form.text_max"
                  :label="$t('quiz.text max')"
                ></v-text-field>
              </v-col>
              <!-- to add nr questions in edit scenario -->
            </v-row>
            <h5 class="mb-3" v-if="norms && norms.length">
              {{ $t("norm.norms") }}:
            </h5>
            <v-row v-for="(item, idx) in norms" :key="item.id" class="mt-0">
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="item.category.category_name"
                  :label="$t('norm.category name')"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-select
                  v-model="item.formula"
                  :items="formule"
                  item-text="text"
                  item-value="id"
                  :label="$t('norm.formula')"
                  :rules="[
                    () =>
                      getInputError('' + idx + '.formula', {
                        name: $t('norm.formula'),
                      }),
                  ]"
                  @input="resetInputError('' + idx + '.formula')"
                ></v-select>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="item.min_val"
                  :label="$t('norm.min val')"
                  type="number"
                  :rules="[
                    () =>
                      getInputError('' + idx + '.min_val', {
                        name: $t('norm.min val'),
                        lt: $t('norm.max val'),
                      }),
                  ]"
                  @input="
                    resetInputError('' + idx + '.min_val');
                    resetInputError('' + idx + '.max_val');
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="item.max_val"
                  :label="$t('norm.max val')"
                  type="number"
                  :rules="[
                    () =>
                      getInputError('' + idx + '.max_val', {
                        name: $t('norm.max val'),
                        gt: $t('norm.min val'),
                      }),
                  ]"
                  @input="
                    resetInputError('' + idx + '.min_val');
                    resetInputError('' + idx + '.max_val');
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("quiz.subscale saved", savedItem)
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
//import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: ["qid"],
  mounted() {
    this.defaultItem.quiz_id = this.qid;
  },
  data() {
    return {
      apiQuery: "/subscale/",
      formule: [
        { id: "sum", text: this.$t("norm.sum") },
        { id: "avg", text: this.$t("norm.avg") },
      ],
      norms: {},
      edited: 0,
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("quiz.subscale") }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("quiz.field"),
            }),
        ],
      },
    };
  },
  watch: {
    editedItem: function () {
      this.norms = [];
      if (this.showFormDialog) {
        if (this.form.id) {
          this.get("/norm/", this.form).then((data) => {
            this.norms = data;
            this.savedItem = null;
          });
        }
      }
    },
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("quiz.Add Subscale")
        : this.$t("quiz.Edit Subscale");
    },
  },

  methods: {
    saveNorms() {
      this.form.quiz_id = this.qid;
      if (this.editedItem === null) {
        this.saveItem();
      } else {
        this.post("/norm?quiz_id=" + this.qid, this.norms)
          .then(() => {
            this.saveItem();
          })
          .catch((error) => {
            this.errorMessage = error.message || error.error;
            this.serverErrors = error.errors;
            this.$refs.form.validate();
            this.$emit("serverError", error);
          });
      }
    },
  },
};
</script>
